import { useEffect, useRef, useState } from 'react';

import { LoadingStatus } from '@icasdigital/icas.core.reactcomponents';
import { createColumnHelper } from '@tanstack/react-table';

import { Columns } from '../constants/columns';
import { Info, StudentInfo } from '../types/Data';
import {
	InitialResultsData,
	InitialResultsInfo,
	ResultsInfo,
} from '../types/Result';
import { renderShortBreakdownName } from '../utils/Formatting';

const columnHelper = createColumnHelper<Info>();

function removeExamBreakdown(data: InitialResultsInfo): ResultsInfo {
	const formattedData: Record<string, string | number | object> = {};
	Object.entries(data).forEach(([key, value]) => {
		// this needs to be like this because of the data shape. Once this has been changed, this can be cleaned up
		if (
			key !== 'ExamBreakdowns' &&
			(typeof value === 'string' ||
				typeof value === 'number' ||
				key === 'Student')
		) {
			formattedData[`${key}`] = value;
		}
	});
	return formattedData as unknown as ResultsInfo;
}

function formatData(data: InitialResultsInfo): ResultsInfo {
	if (!data?.ExamBreakdowns?.length) {
		return removeExamBreakdown(data);
	}
	const breakdowns: Record<string, number> = {};
	data.ExamBreakdowns.forEach(breakdown => {
		const name = renderShortBreakdownName(breakdown.Name);
		breakdowns[name] = breakdown.Grade;
	});
	const newData = removeExamBreakdown(data);
	// eslint-disable-next-line no-console
	console.log(newData);
	return { ...newData, ...breakdowns };
}

function getBreakdownColumnNames(
	data: InitialResultsInfo,
	newColumns: string[]
) {
	if (!data?.ExamBreakdowns?.length) {
		return newColumns;
	}
	data.ExamBreakdowns.forEach(breakdown => {
		const name = renderShortBreakdownName(breakdown.Name);
		if (!newColumns.includes(name)) {
			newColumns.push(name);
		}
	});
	return newColumns;
}

type AddColumnNamesProps = {
	newNames: string[];
	counsellingCommentsColumnNeeded: boolean;
};

function addColumnNames({
	newNames,
	counsellingCommentsColumnNeeded,
}: AddColumnNamesProps) {
	const columns = [...Columns.Results];
	if (!newNames.length && !counsellingCommentsColumnNeeded) {
		return columns;
	}
	if (newNames.length) {
		newNames.forEach(name => {
			const dataName = name.replaceAll(' ', '');
			const newColumn = columnHelper.accessor(dataName, {
				cell: info => info.getValue(),
				header: () => <span>{name}</span>,
				sortingFn: 'alphanumeric',
			});
			columns.push(newColumn);
		});
	}
	if (counsellingCommentsColumnNeeded) {
		const newColumn = columnHelper.accessor('CounsellingComments', {
			header: () => <span>Comments</span>,
			sortingFn: 'text',
		});
		columns.push(newColumn);
	}
	return columns;
}

function renderColumns(data: InitialResultsData) {
	const columns: string[] = [];
	let counsellingCommentsColumnNeeded = false;
	data.forEach(info => {
		const newColumns = getBreakdownColumnNames(info, columns);
		newColumns.forEach(column => {
			if (!columns.includes(column)) {
				columns.push(column);
			}
		});
		//  if any of the results have counselling comments then the column needs rendered
		if (counsellingCommentsColumnNeeded === false) {
			counsellingCommentsColumnNeeded = info.CounsellingComments !== '';
		}
	});
	return addColumnNames({ newNames: columns, counsellingCommentsColumnNeeded });
}

const initialData = [] as ResultsInfo[];

type UseResultsDataProps = {
	data?: InitialResultsData;
	initialDataLoaded: LoadingStatus;
};

/** This is hook is to handle exam breakdowns. It first brings the breakdowns to top level on the data and secondly adds the breakdowns to the column names */

export const useResultsData = ({
	data,
	initialDataLoaded,
}: UseResultsDataProps) => {
	const [loaded, setLoaded] = useState(false);
	const formattedData = useRef(initialData);
	const columns = useRef(Columns.Results);

	useEffect(() => {
		const dataNeedsFormatting =
			!loaded && data && initialDataLoaded === LoadingStatus.LoadedSuccessfully;
		if (dataNeedsFormatting) {
			columns.current = renderColumns(data);
			formattedData.current = data.map(info => formatData(info));
			setLoaded(true);
		}
	}, [loaded, data, initialDataLoaded]);

	return {
		loaded,
		setLoaded,
		formattedData: formattedData.current,
		columns: columns.current,
	};
};
