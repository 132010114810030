import { useState } from 'react';
import { useExportableCSV } from 'use-exportable-csv';

import { LoadingStatus } from '@icasdigital/icas.core.reactcomponents';

import { DownloadButton } from '../../components/Actions/DownloadButton';
import { LevelSelect } from '../../components/Actions/LevelSelect';
import { PageSizeSelect } from '../../components/Actions/PageSizeSelect';
import { SearchBox } from '../../components/Actions/SearchBox';
import { Loader } from '../../components/Loader';
import { StudentView } from '../../components/StudentView/StudentView';
import { Pagination } from '../../components/Table/Pagination';
import { DataTable } from '../../components/Table/Table';
import { Tabs } from '../../components/Tabs/Tabs';
import {
	TabsContent,
	TabsList,
	TabsTrigger,
} from '../../components/Tabs/TabsItems';
import { Columns } from '../../constants/columns';
import { EMPTY_DATA, ORGANISATION_URLS } from '../../constants/constants';
import { useGetData } from '../../hooks/useGetData';
import { useLevel } from '../../hooks/useLevel';
import { useRoleContext } from '../../hooks/useRoleContext';
import { useStudentViewContext } from '../../hooks/useStudentViewContext';
import { useTable } from '../../hooks/useTable';
import { AbsenceType } from '../../types/UrlOptions';
import { renderUrl, stripData } from '../../utils/Formatting';

import '../ContainerStyle.css';

export function isAbsenceType(value: string): value is AbsenceType {
	return (
		value === 'ClassAbsences' ||
		value === 'ExamAbsences' ||
		value === 'WorkshopAbsences'
	);
}

export const Absences = () => {
	const { studentInfo } = useStudentViewContext();
	const showStudentView = studentInfo?.id !== undefined;

	const [absenceType, setAbsenceType] = useState<AbsenceType>('ExamAbsences');

	const { level, setLevel } = useLevel();

	const { role, isOldSyllabusRole } = useRoleContext();

	const url = !role
		? ''
		: renderUrl({
				id: role.OrganisationId,
				url: ORGANISATION_URLS[absenceType],
				levelCode: level,
		  });

	const { data, loadingStatus, setLoadingStatus } = useGetData({ url });
	const { table, globalFilter, setGlobalFilter } = useTable({
		columns: Columns.Absences,
		data,
	});

	const {
		table: examTable,
		globalFilter: examGlobalFilter,
		setGlobalFilter: examSetGlobalFilter,
	} = useTable({
		columns: Columns.ExamAbsences,
		data,
	});

	const options = { bom: true };
	const noData = !data || !data.length;
	const csvData = noData ? EMPTY_DATA : stripData(data);
	const csvLink = useExportableCSV(csvData, options);

	const isNotLoaded =
		loadingStatus !== LoadingStatus.LoadedSuccessfully &&
		loadingStatus !== LoadingStatus.EndedWithError;

	if (isNotLoaded && !data) {
		return <Loader />;
	}

	const onTabsChange = (value: string) => {
		setGlobalFilter('');
		examSetGlobalFilter('');
		if (isAbsenceType(value)) {
			setAbsenceType(value);
		}
		setLoadingStatus(LoadingStatus.IsNotLoading);
	};

	const onLevelChange = (level?: string) => {
		setLevel(level);
		setLoadingStatus(LoadingStatus.IsNotLoading);
	};

	return (
		<div className="app-container with-tabs">
			{showStudentView ? <StudentView /> : null}
			<div>
				<h2 className="with-line">Absences</h2>
				<p>
					Below you will find all absences for your students within the past
					year. Please use the tabs below to toggle between exam, class and
					workshop absences. Absences for subjects on the new curriculum will be
					under the workshop tab while absences for old curriculum subjects will
					remain under the class tab.
				</p>
			</div>
			<div className="filter-container">
				{noData ? <p className="no-results">No results found</p> : null}
				<div style={{ display: 'flex', gap: '24px' }}>
					{absenceType === 'ExamAbsences' ? (
						<>
							<SearchBox
								globalFilter={examGlobalFilter}
								setGlobalFilter={examSetGlobalFilter}
							/>
							<LevelSelect currentLevel={level} setLevel={onLevelChange} />
							<PageSizeSelect table={examTable} />
						</>
					) : (
						<>
							<SearchBox
								globalFilter={globalFilter}
								setGlobalFilter={setGlobalFilter}
							/>
							<LevelSelect currentLevel={level} setLevel={onLevelChange} />
							<PageSizeSelect table={table} />
						</>
					)}
				</div>
				<DownloadButton csvLink={csvLink} dataName="Absences" />
			</div>
			<Tabs value={absenceType} onValueChange={onTabsChange}>
				<TabsList>
					<TabsTrigger value="ExamAbsences">Exam</TabsTrigger>
					{!isOldSyllabusRole ? (
						<>
							<TabsTrigger value="WorkshopAbsences">Workshop</TabsTrigger>{' '}
						</>
					) : (
						<></>
					)}
					<TabsTrigger value="ClassAbsences">Class</TabsTrigger>
				</TabsList>
				<TabsContent value="ExamAbsences">
					<DataTable table={examTable} className="first-tab-child" />
				</TabsContent>
				<TabsContent value="ClassAbsences">
					<DataTable table={table} />
				</TabsContent>
				<TabsContent value="WorkshopAbsences">
					<DataTable table={table} />
				</TabsContent>
			</Tabs>
			<Pagination table={table} />
		</div>
	);
};
