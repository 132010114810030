import { useMemo, useState } from 'react';
import { Content, useExportableCSV } from 'use-exportable-csv';

import { LoadingStatus } from '@icasdigital/icas.core.reactcomponents';

import { Columns } from '../../constants/columns';
import { EMPTY_DATA, STUDENT_URLS } from '../../constants/constants';
import { useGetData } from '../../hooks/useGetData';
import { useLevel } from '../../hooks/useLevel';
import { useStudentViewContext } from '../../hooks/useStudentViewContext';
import { useTable } from '../../hooks/useTable';
import { isAbsenceType } from '../../pages/Absences/Absences';
import { AbsenceType } from '../../types/UrlOptions';
import { renderUrl, stripData } from '../../utils/Formatting';
import { DownloadButton } from '../Actions/DownloadButton';
import { LevelSelect } from '../Actions/LevelSelect';
import { PageSizeSelect } from '../Actions/PageSizeSelect';
import { SearchBox } from '../Actions/SearchBox';
import { Pagination } from '../Table/Pagination';
import { DataTable } from '../Table/Table';
import { Tabs } from '../Tabs/Tabs';
import { TabsContent, TabsList, TabsTrigger } from '../Tabs/TabsItems';

export const AbsencesStudentView = () => {
	const { studentInfo } = useStudentViewContext();

	const [absenceType, setAbsenceType] = useState<AbsenceType>('ExamAbsences');

	const { level, setLevel } = useLevel();

	const urlType = useMemo(() => STUDENT_URLS[absenceType], [absenceType]);

	const url = useMemo(
		() =>
			!studentInfo
				? ''
				: renderUrl({
						id: studentInfo.id,
						url: urlType,
						levelCode: level,
				  }),
		[studentInfo, level, urlType]
	);

	const { data, setLoadingStatus } = useGetData({ url });
	const { table, globalFilter, setGlobalFilter } = useTable({
		columns: Columns.Absences,
		data,
	});
	const {
		table: examTable,
		globalFilter: examGlobalFilter,
		setGlobalFilter: examSetGlobalFilter,
	} = useTable({
		columns: Columns.ExamAbsences,
		data,
	});

	const options = { bom: true };
	const noData = !data || !data.length;
	const csvData = noData ? EMPTY_DATA : stripData(data);
	const csvLink = useExportableCSV(csvData as Content, options);

	if (!studentInfo || !data) {
		return null;
	}

	const onTabsChange = (value: string) => {
		setGlobalFilter('');
		if (isAbsenceType(value)) {
			setAbsenceType(value);
		}
		setLoadingStatus(LoadingStatus.IsNotLoading);
	};

	const onLevelChange = (level?: string) => {
		setLevel(level);
		setLoadingStatus(LoadingStatus.IsNotLoading);
	};

	const downloadName = `${studentInfo.name}-absences`;

	return (
		<div className="student-view-container with-tabs">
			<div className="filter-container">
				<div style={{ display: 'flex', gap: '24px' }}>
					{absenceType === 'ExamAbsences' ? (
						<SearchBox
							globalFilter={examGlobalFilter}
							setGlobalFilter={examSetGlobalFilter}
						/>
					) : (
						<SearchBox
							globalFilter={globalFilter}
							setGlobalFilter={setGlobalFilter}
						/>
					)}
					<PageSizeSelect table={table} />
					<LevelSelect currentLevel={level} setLevel={onLevelChange} />
				</div>
				<DownloadButton csvLink={csvLink} dataName={downloadName} />
			</div>
			<Tabs value={absenceType} onValueChange={onTabsChange}>
				<TabsList>
					<TabsTrigger value="ExamAbsences">Exam</TabsTrigger>
					<TabsTrigger value="WorkshopAbsences">Workshop</TabsTrigger>
					<TabsTrigger value="ClassAbsences">Class</TabsTrigger>
				</TabsList>
				<TabsContent value="ExamAbsences">
					<DataTable table={examTable} className="first-tab-child" />
				</TabsContent>
				<TabsContent value="ClassAbsences">
					<DataTable table={table} />
				</TabsContent>
				<TabsContent value="WorkshopAbsences">
					<DataTable table={table} />
				</TabsContent>
			</Tabs>
			<Pagination table={table} />
		</div>
	);
};
